<template>
    <styled-interface :hide-toolbar="!userIsAdmin">
        <template #interface-toolbar>
            <agency-picker-global />
        </template>

        <template #interface-heading>
            Reports
        </template>

        <styled-card>
            <template #heading>
                Reseller Playbooks
            </template>

            <div class="pt-2 px-4 pb-2">
                <loader v-if="loading" />
                <metabase-embed
                    embed-key="agency-playbooks"
                    :agency-id="agency.id"
                    @load="loading = false"
                    @error="loading = false" />
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import AgencyPickerGlobal from '../globals/AgencyPickerGlobal.vue';
import StyledInterface from '../globals/StyledInterface.vue';
import StyledCard from '../globals/StyledCard.vue';
import Loader from '../globals/Loader.vue';
import MetabaseEmbed from '../globals/MetabaseEmbed.vue';
import { mapGetters, mapState } from 'vuex';


export default {
    components: {
        AgencyPickerGlobal,
        Loader,
        MetabaseEmbed,
        StyledInterface,
        StyledCard
    },
    title: 'Reseller Playbooks Report',
    data() {
        return {
            loading: true
        };
    },
    computed: {
        ...mapGetters([
            'userIsAdmin'
        ]),
        ...mapState({
            agency: (state) => state.agency.currentAgency
        })
    },
    watch: {
        agency() {
            this.loading = true;
        }
    }
};
</script>
