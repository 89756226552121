<template>
    <!-- Use key to create a new iframe with each new URL to ensure history doesn't get affected -->
    <iframe
        v-if="embedUrl"
        v-show="!loading"
        :key="embedUrl"
        ref="embed"
        :src="embedUrl"
        class="metabase-embed"
        :style="activeStyles"
        @load="onEmbedLoad" />
</template>

<script>
import 'iframe-resizer';
import HTTP from '../../http';

export default {
    props: {
        embedKey: {
            type: String,
            required: true
        },
        dealerId: {
            type: [Number,String],
            default: null
        },
        agencyId: {
            type: [Number,String],
            default: null
        },
        vin: {
            type: String,
            default: null
        },
        styles: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            activeStyles: {
                'border': 'none',
                'width': '100%',
                'min-height': '300px'
            },
            loading: false,
            embedUrl: ''
        };
    },
    computed: {
        embedRequest() {
            const params = {
                dealer_id: this.dealerId,
                agency_id: this.agencyId,
                vin: this.vin
            };

            // Delete nulls so the API doesn't get confused when we pass them in
            for (let param in params) {
                if (params[param] === null) {
                    delete params[param];
                }
            }

            // Return an array so it can be spread and passed directly into the post
            return [
                `/reports/metabase/${this.embedKey}`,
                { params }
            ];
        }
    },
    watch: {
        embedRequest() {
            this.getReport();
        }
    },
    created() {
        // Concatenate styles
        this.activeStyles = { ...this.activeStyles, ...this.styles };

        this.getReport();
    },
    beforeDestroy() {
        // Drop the reference before destroy
        // Otherwise it will continue looking for an iframe
        if (this.$refs.embed.iFrameResizer) {
            this.$refs.embed.iFrameResizer.close();
        }
    },
    methods: {
        onEmbedLoad() {
            this.loading = false;

            if (this.$refs.embed.iFrameResizer) {
                this.$refs.embed.iFrameResizer.resize();
            } else {
                // eslint-disable-next-line
                iFrameResize({}, this.$refs.embed);
            }

            this.$emit('load');
        },
        getReport() {
            this.loading = true;
            new HTTP()
                    .post(...this.embedRequest) // Use spread to split the array into arguments
                    .then(response => {
                        if (!response.data.url) {
                            this.loading = false;
                            this.$emit('error', 'No embed URL returned');
                            return;
                        }

                        this.embedUrl = response.data.url;
                    })
                    .catch(e => {
                        this.loading = false;
                        this.$emit('error', e);
                    });
        }
    }
};
</script>
